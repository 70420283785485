// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-draft-js": () => import("./../../../src/pages/draft.js" /* webpackChunkName: "component---src-pages-draft-js" */),
  "component---src-pages-illustrations-js": () => import("./../../../src/pages/illustrations.js" /* webpackChunkName: "component---src-pages-illustrations-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-portfolio-mini-projects-js": () => import("./../../../src/pages/portfolio/mini-projects.js" /* webpackChunkName: "component---src-pages-portfolio-mini-projects-js" */),
  "component---src-pages-portfolio-quarkly-js": () => import("./../../../src/pages/portfolio/quarkly.js" /* webpackChunkName: "component---src-pages-portfolio-quarkly-js" */),
  "component---src-pages-portfolio-stepform-js": () => import("./../../../src/pages/portfolio/stepform.js" /* webpackChunkName: "component---src-pages-portfolio-stepform-js" */),
  "component---src-pages-portfolio-ukit-js": () => import("./../../../src/pages/portfolio/ukit.js" /* webpackChunkName: "component---src-pages-portfolio-ukit-js" */),
  "component---src-pages-portfolio-ukitalt-js": () => import("./../../../src/pages/portfolio/ukitalt.js" /* webpackChunkName: "component---src-pages-portfolio-ukitalt-js" */),
  "component---src-pages-portfolio-webask-js": () => import("./../../../src/pages/portfolio/webask.js" /* webpackChunkName: "component---src-pages-portfolio-webask-js" */)
}

